/** @jsx jsx */
import { jsx } from "@emotion/core"

const Section = props => (
  <section
    css={{
      padding: "50px 0",
      overflow: "hidden",
      "@media (min-width: 768px)": {
        padding: "100px 0",
      },
    }}
    {...props}
  >
    {props.children}
  </section>
)

export default Section
