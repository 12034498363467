/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import React from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import logo from "../../images/logos/simulassur.svg"

class TopBar extends React.Component {
  state = { isHide: false }

  hideBar = () => {
    const { isHide } = this.state

    window.scrollY > this.prev
      ? !isHide && this.setState({ isHide: true })
      : isHide && this.setState({ isHide: false })

    this.prev = 200
  }

  componentDidMount() {
    window.addEventListener("scroll", this.hideBar)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.hideBar)
  }

  render() {
    const classHide = this.state.isHide ? "show" : ""
    return (
      <div
        className={`d-none d-md-block position-fixed ${classHide}`}
        css={css`
          z-index: 999;
          padding: 0.8rem 1rem;
          top: -67px;
          width: 100%;
          background-color: #fff;
          transition: ease-in 0.18s;
          box-shadow: 0 1px 10px rgba(140, 152, 164, 0.3);
          &.show {
            top: 0;
          }
        `}
      >
        <Container>
          <div className="d-flex justify-content-between">
            <Link to="/">
              <img
                src={logo}
                alt="logo simulassur blanc"
                width="188"
                height="40"
              />
            </Link>
            <div className="d-flex align-items-center">
              <p className="mb-0 mr-3">
                <small className="font-weight-bold">
                  Augmentez votre chiffre d'affaires
                </small>
              </p>
              <Link
                className="btn btn-secondary btn-sm"
                to="/inscription/"
                css={css`
                  border-radius: 40px;
                  min-width: auto;
                  padding: 0.5rem 1rem;
                `}
              >
                Devenir partenaire
              </Link>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}

export default TopBar
