import React from "react"
import PropTypes from "prop-types"

import { ThemeProvider } from "emotion-theming"
import Header from "../header"
import Footer from "../Footer"
import TopBar from "../TopBar"
import { ParallaxProvider } from "react-scroll-parallax"

const theme = {
  colors: {
    primary: "#051039",
    secondary: "#349CF5",
  },
  gradients: {
    primary:
      "linear-gradient(315deg, rgba(20, 35, 100, 1) 0%, rgba(5, 16, 57, 1) 100%)",
  },
}

const Layout = ({ footerHalf, footerBg, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <Header />
        <TopBar />
        <main>{children}</main>
        <Footer footerHalf={footerHalf} footerBg={footerBg} />
      </ParallaxProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  footerHalf: PropTypes.bool,
  footerBg: PropTypes.string,
}

Layout.defaultProps = {
  footerHalf: true,
  footerBg: "transparent",
}

export default Layout
