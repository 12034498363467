/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { Link } from "gatsby"
import { Container, Nav, Navbar } from "react-bootstrap"

import logo from "../images/logos/simulassur.svg"
import styled from "@emotion/styled"

const NavLinkStyle = styled.div`
  font-size: 16px;
`

const Header = () => (
  <header
    className="position-absolute w-100"
    css={theme => css`
      background-color: #fff;
      top: 0;
      z-index: 10;
      @media (min-width: 768px) {
        background-color: transparent;
      }
    `}
  >
    <Navbar collapseOnSelect expand="md" variant="dark">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            className="mr-4"
            src={logo}
            alt="logo simulassur"
            width="188"
            height="80"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <NavLinkStyle>
              <Link to="/" className="nav-link" activeClassName={"active"}>
                Notre solution
              </Link>
            </NavLinkStyle>
            <NavLinkStyle>
              <Link
                to="/qui-sommes-nous/"
                className="nav-link"
                activeClassName={"active"}
              >
                Qui sommes-nous ?
              </Link>
            </NavLinkStyle>
            <NavLinkStyle>
              <Link to="/blog/" className="nav-link" activeClassName={"active"}>
                Actualités
              </Link>
            </NavLinkStyle>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </header>
)

export default Header
