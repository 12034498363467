module.exports = {
  postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

  siteTitleMeta: `Simulassur`, // This allows an alternative site title for meta data for pages.
  siteDescriptionMeta: `Comparer les offres des leaders du marché de l’Assurance Emprunteur avec une plateforme 100% Digitale et Innovante.`, // This allows an alternative site description for meta data for pages.
  siteAuthorMeta: "simulassur.fr",
  siteUrl: "https://www.simulassur.fr",
  image: '/banner.jpeg',

  shortTitle: `Simulassur`, // Used for App manifest e.g. Mobile Home Screen
  siteIcon: `src/images/favicon.png`, // Logo in /src/images/ dir used for SEO and App manifest
  backgroundColor: `#051039`, // Used for Offline Manifest
  themeColor: `#ffffff`, // Used for Offline Manifest

  urlBrokerArea: "https://pro.simulassur.fr/",
  simulassurApi: "https://pro.simulassur.fr/",
}
